<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12 text-right mb-3">
        <b-form-checkbox v-model="showDts" name="check-button" switch>{{ $t('seeDT') }}</b-form-checkbox>
      </div>
      <div class="col-12">
        <b-table striped bordered hover :fields="columns" :items="teams">
          <div slot="name" slot-scope="row">
            <router-link :to="{ name: 'TeamHome', params: { lang:lang, console: console, teamSlug: row.item.slug } }">
              <div>
                <img :src="row.item.image" :alt="row.item.name" class="img-icon mr-2">
                {{ row.item.name }}
              </div>
            </router-link>
            <hr v-if="showDts">
            <router-link v-if="showDts && row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.dt.slug } }">
              DT {{ row.item.dt.nick }}
            </router-link>
            <router-link v-if="showDts && row.item.codt && !row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.codt.slug } }">
              CODT {{ row.item.codt.nick }}
            </router-link>
          </div>
          <div slot="position" slot-scope="row" class="text-right">
            {{ row.index + 1 }}
          </div>
          <template slot="points" slot-scope="row">
            <strong>{{ row.item.points }}</strong>
          </template>
          <template slot="dg" slot-scope="row">
            <strong>{{ row.item.gf - row.item.gc }}</strong>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showDts: false,
      teams: [],
      columns: [
        { key: 'position', label: '' },
        { key: 'name', label: this.$t('team'), sortable: false },
        { key: 'points', label: 'Pts', sortable: false },
        { key: 'pj', label: this.$t('pj'), sortable: false },
        { key: 'pg', label: this.$t('pg'), sortable: false },
        { key: 'pe', label: this.$t('pe'), sortable: false },
        { key: 'pp', label: this.$t('pp'), sortable: false },
        { key: 'gf', label: this.$t('gf'), sortable: false },
        { key: 'gc', label: this.$t('gc'), sortable: false },
        { key: 'dg', label: this.$t('dg'), sortable: false },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'currentSeason',
      'divisionFront'
    ])
  },
  watch: {
    currentSeason() {
      this.fetchData()
    }
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'home')
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const cons = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const params = {
        season: this.currentSeason
      }
      this.path = `${lang}/console/${cons}/division/${slug}/position-table`
      this.$axios.get(this.path, { params }).then((response) => {
        const teams = response.data.data
        if (teams.length > 0) {
          const isDown = (teams.length - this.divisionFront.teams_down) - 1
          this.teams = response.data.data.map((row, ind) => {
            if (ind > isDown) {
              row._rowVariant = 'danger'
            }
            return row
          })
        }
      })
    }
  }
}
</script>
